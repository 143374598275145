// @flow
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';

import FaqSection from '_components/FaqSection';
import HeaderSection from '_components/HeaderSection';
import Layout from '_components/Layout';
import ContactSection from '_components/Section/Contact';
import useAnalytics, { event } from '_hooks/useAnalytics';
import HeaderBgImage from '_images/bg-header-faq.jpg';

const Faq = (): React$Node => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  useEffect(() => {
    track({
      group: event.group.Faq,
      action: event.action.Viewed,
    });
  }, [track]);

  return (
    <Layout floatingNavbar={false} inverseFooter>
      <HeaderSection title={t('FAQ_Title')} backgroundImage={HeaderBgImage} />
      <FaqSection />
      <ContactSection />
    </Layout>
  );
};

export default Faq;
