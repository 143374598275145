// @flow
import './index.less';

import { Anchor, Collapse, Select } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useMemo } from 'react';
import Markdown from 'react-markdown';

import useBreakpoint from '_hooks/useBreakpoint';

const FaqSection = (): React$Node => {
  const { t } = useTranslation();

  const isDesktop = useBreakpoint({ min: 'lg' });

  const { allFaqsJson } = useStaticQuery(
    graphql`
      query {
        allFaqsJson {
          edges {
            node {
              name
              key
              faqs {
                title
                description
              }
            }
          }
        }
      }
    `
  );

  const groupedFaqs = useMemo(() => allFaqsJson.edges.map((edge) => edge.node), [allFaqsJson]);

  const onChangeAnchor = useCallback((value) => {
    window.location.hash = `#${value}`;
  }, []);

  const anchorsElem = useMemo(
    () =>
      isDesktop ? (
        <Anchor className="anchors">
          {groupedFaqs.map((group) => (
            <Anchor.Link key={group.key} href={`#${group.key}`} title={group.name} />
          ))}
        </Anchor>
      ) : (
        <Select
          className="rf-select"
          dropdownClassName="rf-select-dropdown"
          placeholder={t('Common_JumpTo')}
          onChange={onChangeAnchor}
        >
          {groupedFaqs.map((group) => (
            <Select.Option value={group.key}>{group.name}</Select.Option>
          ))}
        </Select>
      ),
    [t, isDesktop, groupedFaqs, onChangeAnchor]
  );

  const groupedFaqsElems = useMemo(
    () =>
      groupedFaqs.map((group) => (
        <div key={`grouped-faqs-${group.key}`} id={group.key} className="grouped-faqs">
          <div className="title">{group.name}</div>
          <Collapse className="faqs" bordered={false} expandIconPosition="right">
            {group.faqs.map((faq, idx) => (
              <Collapse.Panel className="faq" header={faq.title} key={idx}>
                <Markdown className="description">{faq.description}</Markdown>
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      )),
    [groupedFaqs]
  );

  return (
    <div className="faq-container">
      {anchorsElem}
      <div className="content">{groupedFaqsElems}</div>
    </div>
  );
};

export default FaqSection;
