// @flow
import './Contact.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Button from '_components/Button';
import SectionTitle from '_components/SectionTitle';

import Section from '.';

const ContactSection = (): React$Node => {
  const { t } = useTranslation();

  return (
    <Section triangles={['top-left', 'bottom-left']} triangleColor="#0f0809" backgroundColor="#160f10">
      <div className="contact-section-container">
        <SectionTitle>{t('ContactSection_Title')}</SectionTitle>
        <Button className="contact-us-btn" size="md" to="/contact">
          {t('Common_ContactUsHere')}
        </Button>
      </div>
    </Section>
  );
};

export default ContactSection;
